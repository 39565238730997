<template>
    <div class="reportListManage_JH">
        <div class="reportListManage_header_JH">
            <span>{{reportListObj.row.reportListName}}</span><span @click="back" class="pointer color">返回</span>
        </div>
        <el-table v-loading="loading"
                  :data="dataList"
                  border
                  style="width: 100%">
            <el-table-column fixed prop="reportName" label="报告名称" align="center"></el-table-column>
            <el-table-column prop="createTime" label="发布日期" align="center">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.createTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="排序" align="center">
                <template slot-scope="scope">
                    <template v-if="!scope.row.showInput">
                        <span @click="cellClick(scope)">{{ scope.row.sort }}</span>
                    </template>
                    <template v-else>
                        <el-input v-focus ref="input" :autofocus="true" v-model="itemSort" size="mini"
                                  @keyup.native="itemSort = itemSort.replace(/[^\d]/g,'')" @blur="sortBlur(scope)"
                                  clearable></el-input>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="modifiedTime" label="修改时间" align="center">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.modifiedTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="操作人" align="center"></el-table-column>
            <el-table-column label="展现设置" align="center">
                <template slot-scope="scope">
<!--                    0显示  1隐藏/-->
                    <span style="cursor: pointer" @click="editHandle(scope)" class="operator color borderColor">{{scope.row.state == 0 ? '隐藏' : '展示'}}</span>
                </template>
            </el-table-column>

        </el-table>
        <div class="pagination_JH">
            <el-pagination
                    background
                    @size-change="handleSizeChange('pageSize',$event)"
                    @current-change="handleSizeChange('page',$event)"
                    :current-page.sync="page"
                    :page-sizes="[10,30,50,100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {reportTimerInfo,updateReportTimerInfo} from '@/api/api'
    import {formatDate} from '@/utils/date.js'
    export default {
        name: "reportListManagement",
        props: {
            reportListObj:Object,
            item: {
                default: null,
                require: false
            },
            value:{
                require: false
            }
        },
        data(){
            return{
                centerDialogVisible: false,
                formLabelWidth: '120px',
                loading:false,
                dataList: [],
                itemSort:"",
                page:1,
                pageSize:10,
                total:0,
            }
        },
        directives: {
            focus: {
                inserted: function (el) {
                    el.querySelector('input').focus()
                }
            }
        },
        created() {
            this.getReportList()
        },
        methods: {
            formatDate(val){
                if(val){
                    return formatDate(new Date(val),'yyyy-MM-dd hh:mm:ss')
                }else{
                    return ""
                }
            },
            handleSizeChange(type,val){
                if(type == 'page'){
                    this.page = val
                }else{
                    this.page = 1
                    this.pageSize = val
                }
                this.getReportList()
            },
            async getReportList(){
                this.loading = true
                const body = {
                    page: this.page,
                    pageSize: this.pageSize,
                    reportTimerId: this.reportListObj.row.id,
                    sord:"desc",
                    sordKey:"sort,create_time"//sort和sordKey先后顺序关系
                }
                console.log('body',body)
                const datas = await reportTimerInfo(body)
                // console.log("datas",datas)
                if(datas && datas.length > 0){
                    const da = JSON.parse(datas)
                    this.total = da.total
                    da.dataList.forEach(item =>{
                        item.showInput = false
                        if(!item.sort){
                            item.sort = 0
                        }
                    })
                    this.dataList = da.dataList
                    console.log('this.dataList',this.dataList)
                }
                // this.sortTable()
                this.loading = false
            },
            sortTable(){
                this.dataList = this.dataList.sort((a,b) =>{
                    if(a.sort != b.sort){
                        return b.sort - a.sort
                    }else{
                        return (a.modifiedTime ? a.modifiedTime : 0) > (b.modifiedTime ? b.modifiedTime : 0) ? -1 : 1
                    }
                })
            },
            back(){
                this.$emit('closeReportList')
            },
            cellClick(val){
                val.row.showInput = val.row.showInput ? false : true
                if(val.row.showInput){
                    this.itemSort = val.row.sort;
                }else{
                    this.itemSort = null;
                }
            },
            async sortBlur(val){
                this.dataList[val.$index].showInput = false
                if(this.itemSort != null && this.itemSort != undefined && this.itemSort != this.dataList[val.$index].sort){
                    //执行修改请求
                    const body = JSON.parse(JSON.stringify(val.row))
                    body.sort = this.itemSort

                    const datas = await updateReportTimerInfo(body.id,body)
                    const data = JSON.parse(datas)
                    this.dataList[val.$index].sort = this.itemSort
                    this.dataList[val.$index].userName = data.userName
                    this.dataList[val.$index].modifiedTime = data.modifiedTime
                    //成功后隐藏数据
                    this.sortTable()
                }
            },
            async editHandle(val){
                const body = JSON.parse(JSON.stringify(val.row))
                body.state = (val.row.state == 0 ? 1 : 0)
                console.log('body',body)
                const datas = await updateReportTimerInfo(body.id,body)
                const data = JSON.parse(datas)
                console.log(data)
                //
                this.dataList[val.$index].state = body.state
                this.dataList[val.$index].userName = data.userName
                this.dataList[val.$index].modifiedTime = data.modifiedTime
                // this.sortTable()
            }
        }
    }
</script>

<style scoped lang="less">
    .reportListManage_JH{
        width:100%;
        height:100%;
        .reportListManage_header_JH{
            display: flex;
            align-items: center;
            text-align: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
            > :nth-child(1){
                font-size:25px;
                font-weight:bold;
            }
            > :nth-child(2){
                margin-left:auto;
            }
        }
    }
</style>
