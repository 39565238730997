<template>
  <div class="userManagement_JH">
    <userManagement :org-id="orgId"></userManagement>
  </div>
</template>

<script>
import userManagement from "./children/userManagement";

export default {
  name: "index",
  components: {
    userManagement
  },
  props: {
    orgId: {
      required: true
    }
  },
  created() {
  }
}
</script>

<style scoped lang="less">
.userManagement_JH{
  width: 100%;
  height: 100%;
}
</style>
