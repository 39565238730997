<template>
  <div class="indexManagement_JH">
    <el-tabs v-model="activeName" @tab-click="handleModalClick">
      <el-tab-pane style="height: calc(100vh - 160px);overflow: scroll"  label="模块管理" name="first">
        <p class="pointer backgroundColor editModal"  @click="editHandle(null)">
          创建模块
        </p>
        <el-table v-loading="loading"
                  :data="dataList"
                  border>
          <el-table-column fixed prop="modularName" label="模块名称" align="center"></el-table-column>
          <el-table-column prop="icon" label="缩略图" align="center">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px"
                        :src="scope.row.icon" :preview-src-list="[scope.row.icon]"></el-image>
            </template>
          </el-table-column>
          <el-table-column  label="分类" align="center">
         <span slot-scope="scope">
          {{scope.row.modifiledUserName ? scope.row.classify : scope.row.classify}}
        </span>
          </el-table-column>
          <el-table-column label="权重" align="center" class-name="showInput" >
            <template slot-scope="scope">
              <template v-if="!scope.row.showInput">
                <p @click="cellClick(scope)">{{scope.row.sort}}</p>
              </template>
              <template v-else>
                <el-input v-focus ref="input" :autofocus="true" v-model="itemSort" style="height:100%;width:100%"
                          @keyup.native="itemSort = itemSort.replace(/[^\d]/g,'')"
                          @blur="sortBlur(scope)" clearable>
                </el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="modifiedTime" label="操作时间" align="center">
        <span slot-scope="scope">
          {{formatDate(scope.row.modifiedTime)}}
        </span>
          </el-table-column>
          <el-table-column  label="操作人" align="center">
         <span slot-scope="scope">
<!--          {{scope.row.modifiledUserName ? scope.row.modifiledUserName : scope.row.createUserName}}-->
          {{scope.row.userName}}
        </span>
          </el-table-column>
          <el-table-column label="操作" align="center">
        <span slot-scope="scope" class="operator color borderColor">
          <span @click="editHandle(scope)">编辑</span>
          <span @click="editHandle(scope,true)">{{scope.row.isShow == 0 ? '隐藏' : '展示'}}</span>
          <span @click="handleClick(scope)">报告</span>
          <span @click="deleteHandler(scope)" type="text" size="small" :style="{color: (scope.row.isShow == 0 ? '#cccccc': '')}">删除</span>
        </span>
          </el-table-column>
        </el-table>
        <div class="pagination_JH" style="position: fixed;bottom: 20px;right: 30px;background: white">
          <el-pagination
                  background
                  @size-change="handleSizeChange('pageSize',$event)"
                  @current-change="handleSizeChange('page',$event)"
                  :current-page.sync="page"
                  :page-sizes="[10,30,50,100]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane  label="分类管理" name="second">
        <p class="pointer backgroundColor editModal" @click="addClassify('add')">
          创建分类
        </p>
        <el-table v-loading="loading"
                  :data="classifyDataList"
                  style="height: calc(100vh - 240px);overflow: scroll"
                  border>
          <el-table-column  label="分类名称" align="center">
         <span slot-scope="scope">
          {{scope.row.name}}
        </span>
          </el-table-column>
            <el-table-column label="权重" align="center" class-name="showInput" >
                <template slot-scope="scope">
                    <template v-if="!scope.row.showInput">
                        <p @click="cellClassIfyClick(scope)">{{scope.row.sort}}</p>
                    </template>
                    <template v-else>
                        <el-input v-focus ref="input" :autofocus="true" v-model="itemSort" style="height:100%;width:100%"
                                  @keyup.native="itemSort = itemSort.replace(/[^\d]/g,'')"
                                  @blur="sortClassIfyBlur(scope)" clearable>
                        </el-input>
                    </template>
                </template>
            </el-table-column>
          <el-table-column prop="modifiedTime" label="操作时间" align="center">
        <span slot-scope="scope">
          {{formatDate(scope.row.modifiedTime) ? formatDate(scope.row.modifiedTime) :formatDate(scope.row.createTime)}}
        </span>
          </el-table-column>
          <el-table-column  label="操作人" align="center">
         <span slot-scope="scope">
<!--          {{scope.row.modifiledUserName ? scope.row.modifiledUserName : scope.row.createUserName}}-->
          {{scope.row.userName}}
        </span>
          </el-table-column>
          <el-table-column label="操作" align="center">
        <span slot-scope="scope" class="operator color borderColor">
          <span @click="addClassify('edit',scope)">编辑</span>
          <span @click="editSure(scope,true)">{{scope.row.state == 0 ? '隐藏' : '展示'}}</span>
          <span @click="deleteClassifyHandler(scope)" type="text" size="small" :style="{color: (scope.row.state == 0 ? '#cccccc': '')}">删除</span>
        </span>
          </el-table-column>
        </el-table>
        <div class="pagination_JH">
          <el-pagination
                  background
                  @size-change="handleSizeClassifyChange('pageSize',$event)"
                  @current-change="handleSizeClassifyChange('page',$event)"
                  :current-page.sync="pageClassify"
                  :page-sizes="[10,30,50,100]"
                  :page-size="pageSizeClassify"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalClassify">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane  label="样式设置" name="third">
        <div style="width: 320px;margin: auto">
          <div style="text-align: left;margin: 30px 0">
            <span style="color: red">*</span>样式：
            <el-radio v-model="styleRadio" label="0">仅模块</el-radio>
            <el-radio v-model="styleRadio" label="1">分类+模块</el-radio>
          </div>
          <div style="text-align: left">
            <span style="color: red">*</span>背景：
            <el-radio v-model="backRadio" label="0">无背景</el-radio>
            <br>
            <el-radio v-model="backRadio" label="1" style="margin-left: 52px;margin-top: 20px">黄色渐变</el-radio>
            <img style="width: 140px;height: 30px;margin-bottom: -10px" src='../../../assets/img/底色.png' alt="">
          </div>
        </div>
        <div style="width: 100%;text-align: center;padding-top: 40px">
          <el-button type="primary" style="height: 30px;line-height: 6px" @click="addOrUp">保存</el-button>

        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :title="editTitle" :visible.sync="dialogFormVisible">
      <el-form :model="forms">
        <el-form-item label="分类名称" :label-width="formLabelWidth">
          <el-input v-model="forms.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="权重" :label-width="formLabelWidth">
          <el-input v-model="forms.sort" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSure">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {debounce} from "../../../utils/debounce";
  import {formatDate} from '@/utils/date.js'
import {getReportModelList, modularUpdate,modularDelete,findModularTypeAll,addModularType,delModularTypeLogic,updateModularType,findModularStyleByOrgId,addOrUp} from "@/api/api";
import {Message} from "element-ui";
export default {
  name: "indexManagement",
  props:{
    item: {
      default: null,
      require: false
    },
    value:{
      default:false,
    },
    orgId:{
      required:true
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector('input').focus()
      }
    }
  },
  created() {
    this.getDataList()
    // this.getClassDataList()
    // this.findModularStyleByOrgId()
  },
  watch:{
    orgId(){
      console.log(this.orgId)
      this.page = 1
      this.getDataList()
      this.getClassDataList()
      this.findModularStyleByOrgId()
    },

    // findModularStyleByOrgId
  },
  data() {
    return {
      styleId:null,
      styleRadio:'0',
      backRadio:'0',
      activeName: 'first',
      loading:false,
      dataList: [],
      classifyDataList:[],
      showInput:false,
      itemSort:"",
      page:1,
      pageSize:10,
      pageClassify:1,
      pageSizeClassify:10,
      total:0,
      totalClassify:0,
      forms: {
        name: '',
        sort: '',
      },
      formLabelWidth: '120px',
      dialogFormVisible:false,
      editTitle:'',
      type:''
    }
  },
  methods: {
    // 防抖
    handleModalClick:debounce(function (tab) {
      if(tab.name == 'first'){
        this.getDataList()
      }else if(tab.name == 'second'){
        this.getClassDataList()
      }else if(tab.name == 'third'){
        this.findModularStyleByOrgId()
      }
    },300),
    // 查询背景
    async findModularStyleByOrgId(){
      console.log("this.orgId",this.orgId)
      if(!this.orgId){
        return
      }
      let orgId = this.orgId
      try{
        let result = await findModularStyleByOrgId(orgId)
        // debugger

        if(result == "null"){
          await this.addOrUp()
        }else{
          this.styleRadio = String(JSON.parse(result).action)
          this.backRadio = String(JSON.parse(result).backdrop)
          this.styleId = JSON.parse(result).id
        }
      }catch (e) {
        console.log(e)
      }
    },
    // 添加样式
    async addOrUp(){
      let body = {
        action:this.styleRadio,//作用域
        backdrop:this.backRadio,//背景
        orgId:this.orgId,
        id:this.styleId
      }
      try {
        let res = await addOrUp(body)
        console.log("res",res)
        await this.findModularStyleByOrgId()
      }catch (e) {
        console.log(e)
      }
    },
    async editSure(scope,showHide){
      if(scope.row && showHide){
        console.log(scope.row)
        let body = {
          id:scope.row.id,
          state:scope.row.state == 0 ? 1 : 0
        }
        try {
          let result = await updateModularType(body)
          this.classifyDataList[scope.$index].state = JSON.parse(result).state
          this.classifyDataList[scope.$index].modifiedTime = JSON.parse(result).modifiedTime
          this.classifyDataList[scope.$index].userName = JSON.parse(result).userName
            // await this.getClassDataList()
          // console.log('this.classifyDataList[scope.$index]',this.classifyDataList[scope.$index])
          // console.log('result',JSON.parse(result))
        }catch (e) {
          console.log(e)
        }
      }else{
        let body = {
          orgId:this.orgId,
          name:this.forms.name,
          sort:this.forms.sort,
        }
        // updateModularType
        try {
          let result
          if(this.type == 'add'){
            result = await addModularType(body)
          }else if(this.type == 'edit'){
            body.id = this.forms.id
            result = await updateModularType(body)
            await this.getDataList()
          }
          console.log("result",result)
          if(result == '名称已存在!'){
            Message({
              message: '名称已存在',
              type: 'error',
            })
            return
          }
          await this.getClassDataList()
        }catch (e) {
          console.log(e)
        }
        this.dialogFormVisible = false
        console.log(this.forms)
      }
    },
    // handleTitleClick(tab, event) {
    //   console.log(tab)
    //   console.log(event)
    //   // console.log(tab, event);
    // },
    formatDate(val){
      if(val){
        return formatDate(new Date(val),'yyyy-MM-dd hh:mm:ss')
      }else{
        return ""
      }
    },
    handleClick(val) {
      //  打开reportManagement.vue
      this.$emit('input',2)
      this.$emit('update:item',val.row)
    },
    handleSizeChange(type,val){
      if(type == 'page'){
        this.page = val
      }else{
        this.pageSize = val
      }
      this.getDataList()
    },
    handleSizeClassifyChange(type,val){
      if(type == 'page'){
        this.pageClassify = val
      }else{
        this.pageSizeClassify = val
      }
      this.getClassDataList()
    },
    async editHandle(val,type){
      if(type){
        const body = {
          id:val.row.id,
          isShow:val.row.isShow == 0 ? 1 : 0
        }
        // const res = await modularUpdate(body)
       await modularUpdate(body)
        this.getDataList()
        // const data = JSON.parse(res)
        // this.dataList[val.$index].isShow = body.isShow
        // this.dataList[val.$index].modifiledUserName = data.modifiledUserName
        // this.dataList[val.$index].modifiedTime = data.modifiedTime
        this.sortTable()
      }else{
        this.$emit('input',1)
        this.$emit("update:item",val && val.row ? val.row  : null)
      }
    },
    async addClassify(type,scope){
      this.type = type
      if(type == 'add'){
        this.forms = {
          name:'',
          sort:''
        }
        this.editTitle = '创建分类'
      }else if(type == 'edit'){
        this.forms = scope.row
        this.editTitle = '编辑分类'
      }
      this.dialogFormVisible = true
    },
    deleteHandler(val){
      if(val.row.isShow == 0){
        this.$message.info("该模块展示中，禁止删除")
        return
      }
      this.$confirm('请确认是否删除该模块，删除后不可恢复！', '提示',
          { confirmButtonText: '确定', cancelButtonText: '取消' ,confirmButtonType:"Primary", type: "warning"})
      .then(()=>{
        modularDelete({id:val.row.id})
        this.dataList.splice(val.$index,1)
      })
      .catch((() =>{
        console.log("取消删除")
      }))
    },
    // deleteClassifyHandler
    deleteClassifyHandler(val){
      console.log("val",val)
      if(val.row.state == 0){
        this.$message.info("该分类展示中，禁止删除")
        return
      }
      this.$confirm('请确认是否删除该分类，删除后不可恢复！', '提示',
              { confirmButtonText: '确定', cancelButtonText: '取消' ,confirmButtonType:"Primary", type: "warning"})
              .then(()=>{
                delModularTypeLogic(val.row.id)
                this.classifyDataList.splice(val.$index,1)
              })
              .catch((() =>{
                console.log("取消删除")
              }))
    },
    cellClick(val){
        console.log('val',val)
      val.row.showInput = val.row.showInput ? false : true
      if(val.row.showInput){
        this.itemSort = val.row.sort;
      }else{
        this.itemSort = null;
      }
    },
      cellClassIfyClick(val){
        console.log('val',val)
      val.row.showInput = val.row.showInput ? false : true
      if(val.row.showInput){
        this.itemSort = val.row.sort;
      }else{
        this.itemSort = null;
      }
    },
    async sortBlur(val){
      this.dataList[val.$index].showInput = false
      if(this.itemSort != null && this.itemSort != undefined && this.itemSort != this.dataList[val.$index].sort){
        //执行修改请求
        const body = {
          id:val.row.id,
          sort:this.itemSort
        }
        const datas = await modularUpdate(body)
        const data = JSON.parse(datas)
        //成功后隐藏数据
        this.dataList[val.$index].sort = this.itemSort
        this.dataList[val.$index].modifiledUserName = data.modifiledUserName
        this.dataList[val.$index].modifiedTime = data.modifiedTime
        this.sortTable()
      }
    },
    async sortClassIfyBlur(val){
      this.classifyDataList[val.$index].showInput = false
      if(this.itemSort != null && this.itemSort != undefined && this.itemSort != this.classifyDataList[val.$index].sort){
        //执行修改请求
        const body = {
          id:val.row.id,
          sort:this.itemSort
        }
        const datas = await updateModularType(body)
        const data = JSON.parse(datas)
        //成功后隐藏数据
        this.classifyDataList[val.$index].sort = this.itemSort
        this.classifyDataList[val.$index].modifiledUserName = data.modifiledUserName
        this.classifyDataList[val.$index].modifiedTime = data.modifiedTime
        this.sortClassIfyTable()
      }
    },
    sortTable(){
      this.dataList = this.dataList.sort((a,b) =>{
        if(a.sort != b.sort){
          return b.sort - a.sort
        }else{
          return a.modifiedTime > b.modifiedTime ? -1 : 1
        }
      })
    },
      sortClassIfyTable(){
      this.classifyDataList = this.classifyDataList.sort((a,b) =>{
        if(a.sort != b.sort){
          return b.sort - a.sort
        }else{
          return a.modifiedTime > b.modifiedTime ? -1 : 1
        }
      })
    },
    // 获取分类列表
    async getClassDataList(){
      console.log("this.orgId",this.orgId)
      if(!this.orgId){
        return
      }
      const body = {
        orgId: this.orgId
      }
      console.log('body',body)
      this.loading = true
      let result = await findModularTypeAll(body,this.pageClassify,this.pageSizeClassify)

      // console.log(result)
      // console.log(JSON.parse(result))
        let dataList = JSON.parse(JSON.stringify(JSON.parse(result).dataList))
        dataList.forEach(item =>{
            item.showInput = false
            // item.modifiedTime = item.modifiedTime ? item.modifiedTime : item.createTime
          })
        this.classifyDataList = dataList
        console.log('this.classifyDataList',this.classifyDataList)
      this.totalClassify = JSON.parse(result).total
      // this.options = JSON.parse(result)
      // const res = await getReportModelList(body)
      // if(res && res.length > 0){
      //   const datas = JSON.parse(res)
      //   datas.dataList.forEach(item =>{
      //     item.showInput = false
      //     item.modifiedTime = item.modifiedTime ? item.modifiedTime : item.createTime
      //   })
      //   this.dataList = datas.dataList
      //   console.log('this.dataList',this.dataList)

      //   this.sortTable()
      // }
      this.loading = false
    },
    async getDataList(){
      if(!this.orgId){
        return
      }
      const body = {
        orgId: this.orgId,
        page:this.page,
        pageSize:this.pageSize
      }
      this.loading = true
      const res = await getReportModelList(body)
      if(res && res.length > 0){
        const datas = JSON.parse(res)
        datas.dataList.forEach(item =>{
          item.showInput = false
          item.modifiedTime = item.modifiedTime ? item.modifiedTime : item.createTime
        })
        this.dataList = datas.dataList
        console.log('this.dataList',this.dataList)
        this.total = datas.total
        this.sortTable()
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.indexManagement_JH {
  width: 100%;
  height: 100%;
  .editModal{
    text-align: center;
    line-height: 34px;
    color: white;
    width: 100px;
    height: 34px;
    margin-bottom: 10px;
    border-radius: 7px;
  }
  > p {
    display: inline-block;
    padding: 0.5rem 2rem;
    border: 1px solid;
    border-radius: 5px;
    color: white;
    margin: 0 0 0.5rem;
  }
  .operator {
    span {
      padding: 0 0.5rem;
      border-right: 1px solid;
      cursor:pointer;
    }

    > :last-child {
      border-right: none;
    }
  }
}
.showInput{
  > div{
    width: 100%;
    height: 100%;
  }
}
</style>
