<template>
<div>
  <div>
    结果：
    <el-select v-model="resState" filterable style="margin-bottom:1rem;"  class="marginRight">
      <el-option :popper-append-to-body="true" v-for="(item,index) in resStateList" :key="index" :label="item" :value="item">
      </el-option>
    </el-select>
    操作时间：
    <el-date-picker
        class="marginRight"
        style="max-width:220px"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="medium"
        :default-time="['','23:59:59']"
        v-model="timeValue"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
    </el-date-picker>
    账号名称：
    <el-input v-model="accountNmae"  style="max-width:220px"  class="marginRight"></el-input>
    <span class="marginRight color pointer" @click="search(true)">搜索</span>
    <span class="marginRight color pointer" @click="search(false)">重置</span>
  </div>
  <el-table :data="dataList" style="width: 100%" max-height="500" :border="false" :stripe="false" v-loading="loading">
    <el-table-column prop="optionUserName" label="账号" width="180" align="center"></el-table-column>
    <el-table-column prop="requestParam" label="手机号" width="180" align="center"></el-table-column>
    <el-table-column prop="operationName" label="行为" align="center"></el-table-column>
    <el-table-column prop="responseMsg" label="结果" align="center"></el-table-column>
    <el-table-column prop="createDate" label="操作时间" align="center">
       <span slot-scope="scope" >
          <span>{{ formatTime(scope.row.createDate)}}</span>
        </span>
    </el-table-column>
  </el-table>
  <div class="controlLog_pag">
    <div>
      <el-pagination
          background
          @size-change="handleSizeChange('pageSize',$event)"
          @current-change="handleSizeChange('page',$event)"
          :current-page.sync="page"
          :page-sizes="[10,30,50,100]"
          :page-size="pageSize"
          layout="total, prev, pager, next,sizes, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import {getControlLogList} from '@/api/api'
export default {
  name: "controlLog",
  data(){
    return {
      loading:false,
      resState:'不限',
      resStateList:['成功','失败','不限'],
      timeValue: [],
      accountNmae:'',
      dataList:[],
      page:1,
      pageSize:10,
      total: 0
    }
  },
  props:{
    orgId: {
      required: true
    },
  },
  watch: {
    orgId(){
      this.getDataList()
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    formatTime(val){
      if(val){
        return val.replaceAll("T"," ").substr(0,val.indexOf("."))
      }else{
        return ""
      }
    },
    processPhone(val){
      if(val){
        const user = JSON.parse(val)
        const userInfos = user.userInfo
        if(userInfos){
          const userInfo = JSON.parse(userInfos)
         return userInfo.mobile
        }
        return ""
      }
      return ""
    },
    search(val){
      if(val){
        this.getDataList()
      }else{
        this.timeValue = null
        this.resState = "不限"
        this.accountNmae = null
      }
    },
    handleSizeChange(type, val) {
      if (type == 'page') {
        this.page = val
      } else {
        this.pageSize = val
      }
      this.getDataList()
    },
    async getDataList(){
      if(!this.orgId){
        return
      }
      this.loading = true
      const body = {
        _page: true,
        option_date_end: this.timeValue && this.timeValue[1] ? this.timeValue[1] : "",
        option_date_start: this.timeValue && this.timeValue[0] ? this.timeValue[0] : "",
        page:this.page,
        pageSize:this.pageSize,
        platform_code: 'iWechatParty',
        customer_code: this.orgId,
        option_user_name:this.accountNmae ? this.accountNmae : null,
        response_msg:this.resState != '不限' ? this.resState : null,
        order_by: "option_date desc"
      }
      const datas = await getControlLogList(body)
      if(datas){
        this.total = datas.total
        this.dataList = datas.list
        console.log(this.dataList)
      }
      this.loading = false

    }
  }
}
</script>

<style scoped>
.controlLog_pag{
  display:flex;
  /*align-items: center;*/
  justify-content: center
}
</style>
