<template>
  <div class="accessStatistics">
    <div class="accessStatisticsHeader" style="position: relative">
      <slot></slot>
      <span v-if="showBack" class="back pointer color" @click="$emit('back')"><返回</span>
    </div>
    <div class="outerTabs">
      <div class="tabs">
        <div v-for="(item,index) in timeList" :key="index" :class="time == item ? 'tabsList active' : 'tabsList'"
             @click="changeTime(item)">
          {{ item }}
        </div>
      </div>
      <span class="datePicker" v-show="showTimeSelect" style="position: absolute;z-index: 1000">
              <el-date-picker
                  @change="datePickerTimeChange"
                  style="max-width:220px"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['','23:59:59']"
                  v-model="timeValue"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
      </el-date-picker>
      </span>
    </div>
  </div>
</template>
<script>
import {formatDate,formatDateForTimestamp} from '@/utils/date'

export default {
  name: "statisticHearder",
  props: {
    value: {
      required: true
    },
    showBack:{
      required: true
    }
  },
  watch: {
    showBack(val){
      this.showTimeSelect = false
    }
  },
  computed: {
    selectTime(){
      if(this.time){
        const s = this.time[0].split(" ")[0]
        const e = this.time[1].split(" ")[0]
        if(s != e){
          return s + " - " + e
        }else{
          return s
        }
      }else{
        return ""
      }
    }
  },
  data() {
    return {
      startTime: formatDate(new Date(), 'yyyy-MM-dd') + ' ' + '00:00:00',
      endTime: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      showTimeSelect: false,
      timeList: ['今天', '昨天', '近七天', '近一个月', '自定义'],
      timeValue:null,
      time:"今天"
    };
  },
  created() {
    this.$emit('input', [this.startTime,this.endTime])
  },
  methods: {
    async changeTime(time) {
      this.time = time
      if (time == '今天') {
        this.showTimeSelect = false
        this.startTime = formatDateForTimestamp(new Date(), 'yyyy-MM-dd') + ' ' + '00:00:00'
        this.endTime = formatDateForTimestamp(new Date(), 'yyyy-MM-dd hh:mm:ss')
      } else if (time == '昨天') {
        this.showTimeSelect = false
        this.startTime = formatDateForTimestamp(new Date().getTime()- 1000 * 60 * 60 * 24,'yyyy-MM-dd')  + ' ' + '00:00:00'
        this.endTime = formatDateForTimestamp(new Date().getTime()- 1000 * 60 * 60 * 24,'yyyy-MM-dd')  + ' ' + '23:59:59'
      } else if (time == '近七天') {
        this.showTimeSelect = false
        this.startTime = formatDateForTimestamp(new Date().getTime() - 1000 * 60 * 60 * 24 * 6, 'yyyy-MM-dd') + ' ' + '00:00:00'
        this.endTime = formatDateForTimestamp(new Date(), 'yyyy-MM-dd') + ' ' + '23:59:59'
      } else if (time == '近一个月') {
        this.showTimeSelect = false
        this.startTime = formatDateForTimestamp(new Date( ).getTime() - 1000 * 60 * 60 * 24 * 29, 'yyyy-MM-dd') + ' ' + '00:00:00'
        this.endTime = formatDateForTimestamp(new Date(), 'yyyy-MM-dd') + ' ' + '23:59:59'
      } else if (time == '自定义') {
        this.timeValue = [this.startTime,this.endTime]
        this.showTimeSelect = !this.showTimeSelect
      }
      if(time != '自定义'){
        this.$emit('input', [this.startTime,this.endTime])
      }
    },
    datePickerTimeChange(time){
      if(time){
       if(time[0] != this.startTime || time[1] != this.endTime){
         this.$emit('input', time)
       }
      }
    }
  }
}
</script>

<style scoped lang="less">
.accessStatistics {
  position: relative;
  background-color: white;
  margin-bottom: 20px;
  width: 100%;
  .detailedBtn {
    font-size: 14px;
    background-color: #0086F9;
    color: white;
    float: right;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    box-shadow: 1px 1px 1px #0086F9;
  }
  .access {
    width: 100%;
    padding: 8px 0;
    height: 100%;
  }

  .access {
    padding: 0 20px;
    box-sizing: border-box;
  }

  .echartTitle {
    font-weight: 700;
    font-size: 18px;
    color: #43546C;

  }

  .echarts {
    //height:290px;
    margin-top: 10px;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 2px 2px #EBEBEB;
  }

  .accessStatisticsHeader {
    font-size: 24px;
    color: #2B314B;
    font-weight: 700;
    width: 100%;
    text-align: center;
    .back{
      font-size:14px;
      float: left;
      line-height:36px;
    }
  }

  .outerTabs {
    margin-top: 20px;
    width: 100%;
    border-bottom: 1px solid #D7D7D8;
    border-top: 1px solid #D7D7D8;
    box-shadow: 1px 1px 1px #D7D7D8;

    .tabs {

      height: 40px;
      background-color: white;
      width: 100%;
      max-width: 768px;
      display: flex;

      .tabsList {
        cursor: pointer;
        line-height: 34px;
        font-size: 14px;
        color: #43546C;
        text-align: center;
        width: 20%;
        border: 3px solid transparent;
      }

      > .active {
        border-bottom-color: #3ea3f9;
        color: #3ea3f9;
      }
    }
  }
}

</style>
