<template>
<div>
  <span @click="$emit('input',undefined)"> <返回 </span>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="消息名称：" prop="name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="消息名称：" prop="type">
      <el-select v-model="form.type" placeholder="请选择活动区域">
        <el-option label="区域一" value="shanghai"></el-option>
        <el-option label="区域二" value="beijing"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="选择报告：" prop="reportName">
      <el-popover
          placement="bottom"
          width="200"
          trigger="click">
        <span class="color" slot="reference">{{ form.reportName ? form.reportName : '选择定时报告' }}</span>
      </el-popover>
    </el-form-item>
    <el-form-item label="通知方式：" prop="notificationType">
      <el-switch v-model="form.notificationType"></el-switch>
    </el-form-item>
    <el-form-item label="发送时间：" prop="notificationTime">
    <span> 报告发送时间</span>
    </el-form-item>
    <el-form-item label="接收人：" prop="recipient">

    </el-form-item>
    <el-form-item label="发送内容：" prop="content">
      <el-input type="textarea" v-model="form.content"></el-input>
    </el-form-item>
    <el-form-item>
<!--      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
<!--      <el-button @click="resetForm('ruleForm')">重置</el-button>-->
    </el-form-item>
  </el-form>
</div>
</template>

<script>
export default {
  name: "messageEditor",
  data(){
    return{
      form:{
        name:"",
        type:"",
        reportName:'',
        reporId:'',
        notificationType:"",
        recipient:[],
        content:""
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
