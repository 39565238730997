<template>
  <div class="indexManageEdit_JH">
    <p><span class="pointer" @click="back">< 返回</span></p>
    <div class="indexManageEdit_content">
      <p>模块设置</p>
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="imageUrl">
          <el-upload
              ref="upload"
              :auto-upload="false"
              class="avatar-uploader"
              action="#"
              :on-change="handleAvatarSuccess"
              :show-file-list="false">
            <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="分类" prop="sort">
          <el-select v-model="form.classify" placeholder="请选择">
            <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权重" prop="sort">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" :autosize="{minRows:4}"></el-input>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button @click="back">取消</el-button>
        <el-button type="primary" @click="confirm" v-loading="loading">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {uploadImg,modularAdd,modularUpdate,findModularTypeList} from "@/api/api";

export default {
  name: "indexManageEdit",
  props: {
    item: {
      default: null,
      require: false
    },
    value:{
      require: false
    },
    orgId: {
      required: true
    }
  },
  data() {
    const checkName = (rule, value, callback) => {
      if(value == null || value == undefined || String(value).trim() == ""){
        callback(new Error('名称不能为空'));
        return
      }
      let len = 0;
      for (let i = 0; i < value.length; i++) {
        let c = value.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
        }
      }
      if (len > 16) {
         callback(new Error('名称最长为8为汉字或16位字符'));
        return
      }
      callback()

    };
    return {
      typeId:'',
      classSort:'',
      options: [],
      loading:false,
      file:null,
      oldImg:null,
      form: {
        name: "",
        imageUrl: "",
        sort: "",
        remark: "",
        classify:""
      },
      rules: {
        name: [
          { validator: checkName, trigger: 'blur' }
        ],
        imageUrl: [
          {required: true, message: '图片不能为空', trigger: 'change'}
        ]
      }
    }
  },
  async created() {
    await this.getFindModularTypeList()
  },
  mounted() {
    if(this.item){
      this.form = {
        name: this.item.modularName,
        imageUrl: this.item.icon,
        sort: this.item.sort,
        remark: this.item.remarks,
        classify:this.item.classify
      }
     this.oldImg =  this.item.icon
    }
  },
  methods: {
    async getFindModularTypeList(){
      try{
        let result = await findModularTypeList(this.orgId)
        this.options = JSON.parse(result)
        console.log('this.options',this.options)
      }catch (e) {
        console.log(e)
      }
    },
    async confirm(){
       await this.$refs['form'].validate(async (valid) => {
         if (valid) {
           if (this.loading) {
             return
           }
           this.loading = true
           //图片改变
           if (this.oldImg != this.form.imageUrl) {
             const data = await uploadImg(this.file)
             if (data) {
               this.form.imageUrl = data
             } else {
               this.$message.error("图片上传失败")
               return
             }
           }
           this.options.map(m => {
             if(m.name == this.form.classify){
               // this.classSort = m.sort
               this.typeId = m.id
             }
           })
           const body = {
             modularName: this.form.name,
             icon: this.form.imageUrl,
             sort: this.form.sort ? this.form.sort : 0,
             remarks: this.form.remark,
             isShow: 1,
             classify:this.form.classify,
             orgId: this.orgId,
             // classSort:this.classSort,
             typeId:this.typeId
           }
           let resp = null
           if (this.item) {
             //修改
             body.id = this.item.id
             resp = await modularUpdate(body)
             this.back()
           } else {
             //新增
             resp = await modularAdd(body)
             this.back()
           }
           this.loading = false
         } else {
           this.$message.info("请校验数据")
           console.log('11111111111111111')
           return false;
         }
       });
    },
    handleAvatarSuccess(res) {
      this.file = res.raw
      this.form.imageUrl = URL.createObjectURL(res.raw);
    },
    back() {
      this.$emit("update:item",null)
      this.$emit('input',0)
    }
  }
}
</script>

<style scoped lang="less">
.indexManageEdit_JH {
  width: 100%;
  .avatar-uploader{
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    //.handler{
    //  background-color:black;
    //  position: absolute;
    //  width: 178px;
    //  height: 178px;
    //  top:0;
    //  >span{
    //    margin-top:85px;
    //  }
    //  > :nth-child(2){
    //    margin-left:20px;
    //    color:red;
    //  }
    //}
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .indexManageEdit_content {
    width: 50%;
    @media screen and (max-width: 500px) {
      width: 80%;
    }
    @media screen and (min-width: 500px) and (max-width: 1000px) {
      width: 60%;
    }

    margin-left: 2rem;

    p {
      font-size: 20px;
      @media screen and (max-width: 300px) {
        font-size: 16px;
      }
      @media screen and (min-width: 300px) and (max-width: 800px) {
        font-size: 18px;
      }
      font-weight: bold;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
}
.button{
  text-align: center;
}
</style>
