//防抖

export function debounce(fn,timerDelay){
    var timerDelay=timerDelay||300;
    var timer;
    return function (){
        var that=this;
        var params=arguments;
        if(timer) clearTimeout(timer)
        timer=setTimeout(()=>{
            fn.apply(that,params)
        },timerDelay)
    }
}