<template>
  <transition name="fade-transform" mode="out-in">
    <indexManagement v-if="homePageFlag == 0" v-model="homePageFlag" :item.sync="item" :orgId="orgId"></indexManagement>
    <indexManageEdit v-else-if="homePageFlag == 1" v-model="homePageFlag" :item.sync="item" :orgId="orgId"></indexManageEdit>
    <reportManagement v-else-if="homePageFlag == 2" v-model="homePageFlag" :item.sync="item"></reportManagement>
  </transition>
</template>

<script>
import indexManagement from "./children/indexManagement";
import indexManageEdit from "./children/indexManageEdit";
import reportManagement from "./children/reportManagement";
export default {
  name: "index",
  components: {
    reportManagement,
    indexManagement,
    indexManageEdit,
  },
  data() {
    return {
      orgList: [],
      index: "1",
      homePageFlag: 0,
      item: null
    }
  },
  watch:{
    homePageFlag(){
      this.$emit("changeSelectShow",this.homePageFlag == 0)
    }
  },
  props: {
    orgId: {
      required: true
    }
  },
}
</script>

<style scoped lang="less">
.control_JH {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns:  10vw 90vw;

  > :first-child {
    //height:100%;
  }

  > :nth-child(2) {
    padding: 1rem;
  }
}
</style>
