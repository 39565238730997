<template>
  <transition name="fade-transform" mode="out-in">
<!--    <messageManagement v-if="index == '4-1' && item === undefined" v-model="item"></messageManagement>-->
<!--    <messageEditor v-if="index == '4-1'  && item !== undefined"  v-model="item"></messageEditor>-->
    <messageLogging v-if="index == '4-2'" :orgId="orgId"></messageLogging>
  </transition>
</template>

<script>
import messageLogging from "./children/messageLogging";
import messageManagement from "./children/messageManagement";
import messageEditor from "./children/messageEditor";
export default {
  name: "index",
  components: {
    messageLogging,
    messageManagement,
    messageEditor
  },
  props: {
    orgId: {
      required: true
    },
    index: {
      type: String,
      required: true
    }
  },
  data(){
    return{
      item:undefined
    }
  }
}
</script>

<style scoped lang="less">

</style>
