<template>
  <div class="accessRecord">
    <div>
      <div>
        {{selectTime}}
      </div>
      <div>
        <el-input v-model="name" clearable style="max-width:220px"></el-input><span class="pointer color" @click="getDataList">搜索</span>
      </div>
    </div>
    <el-table :data="dataList" style="width: 100%" max-height="500" :border="false" :stripe="false">
      <el-table-column label="访问时间" width="180" align="center">
         <span slot-scope="scope" >
          <span>{{ formatDateForTimestamps(scope.row.visitTime) }}</span>
        </span>
      </el-table-column>
      <el-table-column prop="userId" label="openid" width="180" align="center"></el-table-column>
      <el-table-column prop="userName" label="姓名" align="center"></el-table-column>
      <el-table-column prop="departmentName" label="部门" align="center"></el-table-column>
      <el-table-column prop="reportName" label="报告" align="center">
        <span slot-scope="scope" >
          <span class="color">{{ scope.row.reportName ? scope.row.reportName : scope.row.reportName }}</span>
        </span>
      </el-table-column>
    </el-table>
    <div class="controlLog_pag">
      <div>
        <el-pagination
            background
            @size-change="handleSizeChange('pageSize',$event)"
            @current-change="handleSizeChange('page',$event)"
            :current-page.sync="page"
            :page-sizes="[10,30,50,100]"
            :page-size="pageSize"
            layout="total, prev, pager, next,sizes, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getAccessRecord} from '@/api/api'
import {formatDateForTimestamp} from '@/utils/date'
export default {
  name: "accessRecord",
  data(){
    return {
      resState:'',
      resStateList:[],
      timeValue: [],
      accountNmae:'',
      dataList:[],
      page:1,
      pageSize:10,
      total: 0,
      name:''
    }
  },
  props: {
    time:{
      required: true
    },
    apendId: {
      required: true
    },
  },
  created() {
    this.getDataList()
  },
  watch: {
    apendId(){
      this.getDataList()
    },
    time:{
      handler(){
        this.getDataList()
      },
      deep:true
    },
  },
  computed: {
    selectTime(){
      if(this.time){
        const s = this.time[0].split(" ")[0]
        const e = this.time[1].split(" ")[0]
        if(s != e){
          return s + " - " + e
        }else{
          return s
        }
      }else{
        return ""
      }
    }
  },
  methods: {
    formatDateForTimestamps(val){
      return formatDateForTimestamp(val,'yyyy-MM-dd hh:mm:ss')
    },
    search(val){
      if(val){
        this.getDataList()
      }else{
        this.timeValue = null
        this.resState = ""
        this.accountNmae = null
      }
    },
    handleSizeChange(type, val) {
      if (type == 'page') {
        this.page = val
      } else {
        this.pageSize = val
      }
      this.getDataList()
    },
    async getDataList(){
      if(!this.apendId){
        return
      }
      const body ={
        likeUserName:this.name,
        page:this.page,
        pageSize:this.pageSize,
        startTime:this.time[0],
        endTime:this.time[1],
        agentId:this.apendId,
        orderByClause:"desc"
      }
      const datas = await getAccessRecord(body)
      if(datas){
        const data = JSON.parse(datas)
        this.total = data.total
        this.dataList = data.list
      }

    }
  }
}
</script>

<style scoped lang="less">
.accessRecord{
  > :first-child {
    display: flex;
    justify-content:space-around;
    align-items: center;
    > :last-child{
      display: flex;
      align-items: center;
      >span{
        margin-left:20px;
        white-space:nowrap;
      }
    }
  }
}
.controlLog_pag{
  >div{
    float: right;
  }
}
</style>
