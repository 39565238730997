<template>
  <div class="messageMenagement_JH">
    <div class="messageMenagement_header_JH">
      <span>
        <el-button type="primary" @click="$emit('input',null)">创建消息</el-button>
      </span>
      <span>
        <el-input v-model="keyWord" style="max-width:220px" class="marginRight"/><span>搜索</span>
      </span>
    </div>
    <el-table v-loading="loading"
              :data="dataList"
              style="max-height: 700px">
      <el-table-column prop="name" label="消息名称" align="center" fixed></el-table-column>
      <el-table-column prop="type" label="消息类型" align="center"></el-table-column>
      <el-table-column prop="reportTime" label="通知方式" align="center"></el-table-column>
      <el-table-column prop="type1" label="修改时间" align="center"></el-table-column>
      <el-table-column prop="time" label="操作人" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <p slot-scope="scope" class="operator borderColor">
          <span  class="operator color pointer">启用</span>
          <span class="operator color pointer"  @click="$emit('input',scope.row)">编辑</span>
          <span class="operator pointer">删除</span>
        </p>
      </el-table-column>
    </el-table>
    <div class="pagination_JH">
      <el-pagination
          background
          @size-change="handleSizeChange('pageSize',$event)"
          @current-change="handleSizeChange('page',$event)"
          :current-page.sync="page"
          :page-sizes="[10,30,50,100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>


export default {
  name: "messageNotification",
  props:{
    value:{
      type:Object,
      default:null
    }
  },
  data() {
    return {
      keyWord: '',
      loading: false,
      dataList: [{name:"111111"}],
      page:1,
      pageSize:10,
      total: 0
    }
  },
  methods: {
    handleSizeChange(type,val){
      if(type == 'page'){
        this.page = val
      }else{
        this.pageSize = val
      }
      this.getDataList()
    },
    getDataList(){
    }
  }
}
</script>

<style scoped lang="less">
.messageMenagement_JH {
  width: 100%;
  height: 100%;
  .messageMenagement_header_JH{
    display: flex;
    margin-bottom: 1rem;
    white-space: nowrap;
    > :last-child{
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
    }
  }
}
.operator{
  span{
    padding: 1rem;
  }
  > :nth-child(2){
    border-left: 1px solid;
    border-right: 1px solid;
  }
}
</style>
