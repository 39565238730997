<template>
  <div>
    <statisticHearder v-model="selectTime" :showBack="showBack" @back="back">{{title}}</statisticHearder>
    <transition name="fade-transform" mode="out-in">
      <accessStatistics ref='accessStatistics' :apendId="apendId" :time="selectTime" v-if="index == '3-1'" @changeTitle="changeTitle" @changeShowBack="changeShowBack"></accessStatistics>
      <accessRecord ref='accessRecord' :apendId="apendId" :time="selectTime" v-if="index == '3-2'"></accessRecord>
    </transition>
  </div>
</template>
<script>
import accessStatistics from '@/views/accessStatisticsForControl/components/accessStatistics'
import accessRecord from '@/views/accessStatisticsForControl/components/accessRecord'
import statisticHearder from "@/views/accessStatisticsForControl/components/statisticHearder";
import {getWeixinAccessAgent} from '@/api/api'
export default {
  name: "index",
  components: {
    accessStatistics,
    accessRecord,
    statisticHearder
  },
  data(){
    return{
      title:"访问统计",
      selectTime:['',''],
      showBack:false,
      apendId:''
    }
  },
  props: {
    orgId: {
      required: true
    },
    index: {
      type: String,
      required: true
    }
  },
  watch:{
    index(){
      this.showBack = false
      this.title = "访问统计"
    },
    async orgId() {
      await this.getApendId()
    }
  },
  created() {
    this.getApendId()
  },
  methods: {
    async getApendId() {
      if (!this.orgId) {
        return
      }
      const body = {
        orgId: this.orgId
      }
      const data = await getWeixinAccessAgent(body)
      this.apendId = data
    },
    changeTitle(val){
      this.title = val
    },
    changeShowBack(val){
      this.showBack = val
    },
    back(){
      this.$emit('changeSelectShow',true)
      this.$refs.accessStatistics.showDetailedFun()
    }
  }
}
</script>
<style scoped>

</style>
