<template>
  <div class="reportListManage_JH">
    <div v-if="!showReportList">
      <div class="reportListManage_header_JH">
        <span>{{item ? item.modularName : ""}}</span><span @click="back" class="pointer color">返回</span>
      </div>
      <el-table v-loading="loading"
                :data="dataList"
                border
                style="width: 100%">
        <el-table-column fixed prop="reportName" label="报告名称" align="center"></el-table-column>
        <el-table-column label="报告列表名称" align="center" prop="reportListName" >
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            <template v-if="!scope.row.showInput">
              <span @click="cellClick(scope)">{{ scope.row.sort }}</span>
            </template>
            <template v-else>
              <el-input v-focus ref="input" :autofocus="true" v-model="itemSort" size="mini"
                        @keyup.native="itemSort = itemSort.replace(/[^\d]/g,'')" @blur="sortBlur(scope)"
                        clearable></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="modifiedTime" label="修改时间" align="center">
          <template slot-scope="scope">
            <span>{{formatDate(scope.row.modifiedTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="操作人" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
        <span slot-scope="scope">
          <span>{{ scope.row.enableReport == 1 ?  '隐藏' : '展示'  }}</span>
        </span>
        </el-table-column>
        <el-table-column label="通知" align="center">
        <span slot-scope="scope">
          <span>{{ scope.row.state == 1 ?  '停用' : '启用'  }}</span>
        </span>
        </el-table-column>
        <el-table-column label="点击处理" align="center">
        <span slot-scope="scope">
          <span>{{ scope.row.clickState == 0 ?  '展示报告' : '展示列表'  }}</span>
        </span>
        </el-table-column>
        <el-table-column label="操作" align="center">
        <span slot-scope="scope"  class="operator color pointer borderColor">
          <span @click="toSet(scope)">设置</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="toReportList(scope)">报告列表</span>
        </span>
        </el-table-column>

      </el-table>
      <div class="pagination_JH">
        <el-pagination
                background
                @size-change="handleSizeChange('pageSize',$event)"
                @current-change="handleSizeChange('page',$event)"
                :current-page.sync="page"
                :page-sizes="[10,30,50,100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
      </div>
      <el-dialog
              title="设置"
              :visible.sync="centerDialogVisible"
              width="400px"
              center>
        <div>
          展现设置：
          <el-radio style="margin-right: 86px" v-model="showRadio" label="0">展现</el-radio>
          <el-radio v-model="showRadio" label="1">隐藏</el-radio>
        </div>
        <div style="margin-top: 14px">
          通知设置：
          <el-radio style="margin-right: 86px" v-model="stopRadio" label="0">启用</el-radio>
          <el-radio v-model="stopRadio" label="1">停用</el-radio>
        </div>
        <div style="margin-top: 14px">
          点击处理：
          <el-radio v-model="newRadio" label="0">展现最新报告</el-radio>
          <el-radio v-model="newRadio" label="1">展现报告列表</el-radio>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button style="height: 30px;line-height: 5px" @click="centerDialogVisible = false">取 消</el-button>
    <el-button style="height: 30px;line-height: 5px" type="primary" @click="setSure">确 定</el-button>
  </span>
      </el-dialog>
    </div>

    <reportListManagement :reportListObj="reportListObj" @closeReportList="closeReportList" v-if="showReportList"></reportListManagement>
  </div>
</template>

<script>
import {getReportList,updateReport} from '@/api/api'
import {formatDate} from '@/utils/date.js'
import reportListManagement from "./reportListManagement";
export default {
  name: "reportManagement",
  components:{
    reportListManagement
  },
  props: {
    item: {
      default: null,
      require: false
    },
    value:{
      require: false
    }
  },
  data(){
    return{
      reportListObj:{},
      showReportList:false,
      setBody:{},
      showRadio:null,
      stopRadio:null,
      newRadio:null,
      centerDialogVisible: false,
      formLabelWidth: '120px',
      loading:false,
      dataList: [],
      itemSort:"",
      page:1,
      pageSize:10,
      total:0,
      modulaId:""
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector('input').focus()
      }
    }
  },
  created() {
    if(this.item){
      this.modulaId = this.item.id
    }
    this.getReportList()
  },
  methods: {
    async setSure(){
      let form = JSON.parse(JSON.stringify(this.setBody.row))
      form.enableReport = Number(this.showRadio)//状态1隐藏0展示
      form.state = Number(this.stopRadio)//是否启用  1停用  0启用
      form.clickState = Number(this.newRadio)//点击处理0展现报告1展现列表
      console.log(form)
      try {
        await updateReport(form.id,form)
        await this.getReportList()
        //成功后隐藏数据
        // this.sortTable()
        this.centerDialogVisible = false
      }catch (e) {
        console.log(e)
      }

    },
    toSet(val){
      this.centerDialogVisible = true
      console.log('val',val.row)
      this.setBody = val
      this.showRadio = String(val.row.enableReport)//状态0隐藏1展示
      this.stopRadio = String(val.row.state)//是否启用0停用1启用
      this.newRadio = String(val.row.clickState)//点击处理0展现报告1展现列表
    },
    toReportList(val){
      this.reportListObj = val
      console.log(this.reportListObj)
      this.showReportList = true
      console.log('报告列表',val)
    },
    closeReportList(){
      this.showReportList = false
    },
    formatDate(val){
      if(val){
        return formatDate(new Date(val),'yyyy-MM-dd hh:mm:ss')
      }else{
        return ""
      }
    },
    handleSizeChange(type,val){
      if(type == 'page'){
        this.page = val
      }else{
        this.page = 1
        this.pageSize = val
      }
      this.getReportList()
    },
    async getReportList(){
      if(!this.modulaId){
        return
      }
      this.loading = true
      const body = {
        page: this.page,
        pageSize: this.pageSize,
        modulaId: this.modulaId
      }
      const datas = await getReportList(body)
      if(datas && datas.length > 0){
        const da = JSON.parse(datas)
        this.total = da.total
        da.dataList.forEach(item =>{
          item.showInput = false
          if(!item.sort){
            item.sort = 0
          }
        })
        this.dataList = da.dataList
        console.log('this.dataList',this.dataList)
      }
      this.sortTable()
      this.loading = false
    },
    sortTable(){
      this.dataList = this.dataList.sort((a,b) =>{
        if(a.sort != b.sort){
          return b.sort - a.sort
        }else{
          return (a.modifiedTime ? a.modifiedTime : 0) > (b.modifiedTime ? b.modifiedTime : 0) ? -1 : 1
        }
      })
    },
    back(){
      this.$emit('input',0)
      this.$emit('update:item',null)
    },
    cellClick(val){
      val.row.showInput = val.row.showInput ? false : true
      if(val.row.showInput){
        this.itemSort = val.row.sort;
      }else{
        this.itemSort = null;
      }
    },
    async sortBlur(val){
      this.dataList[val.$index].showInput = false
      if(this.itemSort != null && this.itemSort != undefined && this.itemSort != this.dataList[val.$index].sort){
        //执行修改请求
        const body = JSON.parse(JSON.stringify(val.row))
        body.sort = this.itemSort

        const datas = await updateReport(body.id,body)
        const data = JSON.parse(datas)
        this.dataList[val.$index].sort = this.itemSort
        this.dataList[val.$index].userName = data.userName
        this.dataList[val.$index].modifiedTime = data.modifiedTime
        //成功后隐藏数据
        this.sortTable()
      }
    },
    async editHandle(val){
      const body = JSON.parse(JSON.stringify(val.row))
      body.enableReport = (val.row.enableReport == 0 ? 1 : 0)
      console.log('body',body)
      const datas = await updateReport(body.id,body)
      const data = JSON.parse(datas)
      console.log(data)

      this.dataList[val.$index].enableReport = body.enableReport
      this.dataList[val.$index].userName = data.userName
      this.dataList[val.$index].modifiedTime = data.modifiedTime
      this.sortTable()
    }
  }
}
</script>

<style scoped lang="less">
.reportListManage_JH{
  width:100%;
  height:100%;
  .reportListManage_header_JH{
    display: flex;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    > :nth-child(1){
      font-size:25px;
      font-weight:bold;
    }
    > :nth-child(2){
      margin-left:auto;
    }
  }
}
</style>
