<template>
  <div>
    <div class="messageLog_JH">
      发送状态：
      <el-select v-model="state" placeholder="请选择" size="medium" class="marginRight">
        <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      发送时间：
      <el-date-picker
          class="marginRight"
          style="max-width:220px"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="medium"
          :default-time="['','23:59:59']"
          v-model="timeValue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
      消息名称:
      <el-input clearable v-model="likeName" style="max-width:220px" class="marginRight">></el-input>

      <span class="marginRight pointer color" @click="search">搜索</span>
      <span class="pointer color" @click="rest">重置</span>

    </div>
    <el-table v-loading="loading"
              :data="dataList"
              style="max-height: 700px"
              border>
      <el-table-column prop="name" label="消息名称" align="center"></el-table-column>
      <el-table-column label="消息类型" align="center">定时报告</el-table-column>
      <el-table-column prop="realSendTime" label="报告发布时间" align="center">
      </el-table-column>
      <el-table-column prop="noticeType" label="通知方式" align="center">
        <span slot-scope="scope" >
          <span v-if="scope.row.noticeType == 1">微信</span>
          <span v-if="scope.row.noticeType == 2">钉钉</span>
        </span>
      </el-table-column>
      <el-table-column prop="realSendTime" label="发送时间" align="center">
      </el-table-column>
      <el-table-column label="发送状态" align="center">
        <span slot-scope="scope" class="operator">
          <span v-if="scope.row.state == 0">未发送</span>
          <span v-else-if="scope.row.state == 1">成功</span>
          <span v-else><span style="margin-right: 10px">失败</span>
              <el-popover
                  placement="top"
                  trigger="click"
                  width="200">
                <div style="text-align: center">
                 {{scope.row.msgText }}
                </div>
                 <span slot="reference" class="color pointer">详情</span>
              </el-popover>
          </span>
        </span>
      </el-table-column>
    </el-table>
    <div class="pagination_JH">
      <el-pagination
          background
          @size-change="handleSizeChange('pageSize',$event)"
          @current-change="handleSizeChange('page',$event)"
          :current-page.sync="page"
          :page-sizes="[10,30,50,100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {formatDate} from '@/utils/date'
import {getMessageList} from "@/api/api";

export default {
  name: "messageLogging",
  props: {
    orgId: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      state: '-1',
      stateList: [{value: "-1", label: '不限'}, {value: '0', label: '未发送'}, {value: '1', label: '成功'}, {value: '2', label: '失败'}],
      timeValue: [],
      likeName: "",
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.initTime()
    this.getDataList()
  },
  watch:{
    orgId(){
      this.getDataList()
    }
  },
  methods: {
    initTime() {
      this.timeValue = [formatDate(new Date(), 'yyyy-MM-dd') + ' ' + '00:00:00', formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')]
    },
    rest() {
      this.state = "-1"
      this.initTime()
      this.likeName = ""
      this.page = 1
      this.getDataList()
    },
    search(){
      this.page = 1
      this.getDataList()
    },
    async getDataList() {
      if(!this.orgId){
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      const body = {
        likeName: this.likeName,
        startTime: this.timeValue ? this.timeValue[0] : null,
        endTime: this.timeValue ? this.timeValue[1] : null,
        orgId: this.orgId,
        page: this.page,
        pageSize: this.pageSize
      }
      if (this.state != '-1') {
        body.state = this.state
      }
      const data = await getMessageList(body)
      this.total = data.total
      this.dataList = data.list
      this.sortTable()
      this.loading = false
    },
    sortTable(){
        this.dataList = this.dataList.sort((a,b) =>{
          return a.realSendTime > b.realSendTime ? -1 : 1
        })
    },
    handleSizeChange(type, val) {
      if (type == 'page') {
        this.page = val
      } else {
        this.pageSize = val
      }
      this.getDataList()
    },
  }
}
</script>

<style scoped>
.messageLog_JH {
  margin: 10px 0;
}
</style>
