<template>
  <div class="control_JH">
    <div>
      <el-menu style="height:100%;width: 180px"
               @select="selectMenu"
               :default-active="index">
        <el-menu-item index="1">
          <span>首页管理</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">用户管理</span>
        </el-menu-item>
        <el-submenu index="3">
          <template slot="title">访问统计</template>
          <el-menu-item index="3-1">访问统计</el-menu-item>
          <el-menu-item index="3-2">访问记录</el-menu-item>
        </el-submenu>
        <el-submenu index="4">
          <span slot="title">消息通知</span>
<!--          <el-menu-item index="4-1">消息管理</el-menu-item>-->
          <el-menu-item index="4-2">消息记录</el-menu-item>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">日志记录</template>
          <el-menu-item index="5-1">控制台日志</el-menu-item>
          <el-menu-item index="5-2">客户端日志</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div>
      <el-card style="height:100%;overflow: scroll">
        <el-select v-model="orgId"  v-if="selectShow" filterable @change="orgIdChange" style="margin-bottom:1rem;">
          <el-option :popper-append-to-body="true" v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <div style="height:100%">
          <transition name="fade-transform" mode="out-in">
            <component :is="currentRouter" :orgId="orgId" @changeSelectShow="changeSelectShow" :index="index" @hook:destroyed="destroyedFun" />
          </transition>
        </div>
      </el-card>
    </div>

  </div>
</template>

<script>
import homeManagementIndex from '@/views/homeManagement/index.vue'
import  messageIndex from '@/views/ messageNotification/index.vue'
import userManagementIndex from '@/views/userMessage/index.vue'
import {getClientList} from "@/api/api";
import accessStatisticsIndex from '@/views/accessStatisticsForControl/index.vue'
import logIndex from '@/views/logRecord/index.vue'
export default {
  name: "controlJH",
  components: {
    homeManagementIndex,
    messageIndex,
    userManagementIndex,
    accessStatisticsIndex,
    logIndex
  },
  data() {
    return {
      selectShow:true,
      orgList:[],
      index: "1",
      orgId: "",
    }
  },
  async created() {
    const index = sessionStorage.getItem('inewsReportIndex')
    if(index){
      this.index = index
    }
    await this.getClientList()
    if(this.orgList && this.orgList.length > 0){
      if(this.$store.state.orgId){
        const org = this.orgList.find(item => item.id == this.$store.state.orgId)
        if(org){
          this.orgIdChange(this.$store.state.orgId)
        }else{
          this.orgIdChange(this.orgList[0].id)
        }
      }else{
        this.orgIdChange(this.orgList[0].id)
      }
    }
  },
  computed:{
    currentRouter(){
      if(this.index == 1){
        return 'homeManagementIndex'
      }
      else if(this.index == 2){
        return 'userManagementIndex'
      }
      else if(this.index.includes('3')){
        return 'accessStatisticsIndex'
      }
      else if(this.index.includes('4')){
        return 'messageIndex'
      }
      else if(this.index.includes('5')){
        return 'logIndex'
      }
    }
  },
  methods: {
    destroyedFun(){

    },
    changeSelectShow(val){
      this.selectShow = val
    },
    orgIdChange(val){
      this.$store.commit('setOrgId',{orgId:val})
      sessionStorage.setItem('orgId',val)
      this.orgId = val
    },
    async getClientList() {
      const data = await getClientList()
      const dataList = JSON.parse(data).orgs
      dataList.forEach(x => x.id = String(x.id))
      this.orgList = dataList

    },
    selectMenu(val) {
      if (val != this.index) {
        this.index = val
        this.selectShow = true

        sessionStorage.setItem('inewsReportIndex',this.index)
      }
    },
  }
}
</script>

<style scoped lang="less">
.control_JH {
  width: 100%;
  height: 100vh;
  display: flex;
  grid-template-columns:  10vw 90vw;

  > :first-child {
    //height:100%;
    width: 180px;
  }

  > :nth-child(2) {
    padding: 1rem;
    width: calc(100vw - 180px);
  }
  ::v-deep .el-submenu .el-menu-item{
    min-width:100% !important;
  }
}
</style>
