<template>
  <transition name="fade-transform" mode="out-in">
    <controlLog v-if="index == '5-1'" :orgId="orgId"></controlLog>
    <clientLog v-if="index == '5-2'" :orgId="orgId"></clientLog>
  </transition>
</template>
<script>
import controlLog from './children/controlLog'
import clientLog from './children/clientLog'

export default {
  name: "index",
  props: {
    orgId: {
      required: true
    },
    index: {
      type: String,
      required: true
    }
  },
  components: {
    controlLog,
    clientLog
  },
}
</script>

<style scoped>

</style>
