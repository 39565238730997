<template>
  <div class="accessStatistics">
    <transition name="fade-transform" mode="out-in">
      <template v-if="showDetailed">
        <detaile @getAccessTrends="getAccessTrends"
                 :val="val"
                 :agentid="String(apendId)"
                 :selectTime="selectTime"
                 :startTime="time[0]"
                 :endTime="time[1]"
                 :showDetailed="showDetailed"
                 :dataList="detaileArr"
                 :type="type"
                 :header="header"></detaile>
      </template>
      <section v-else>
        <div class="showTime">
          {{ selectTime }}
        </div>
        <div class="access">
          <span class="echartTitle">报告访问趋势</span>
          <lineAreas :startTime="time[0]" :endTime="time[1]" :dataList="newAccessTrendsList"
                     :key="key"
                     :showDetailed="showDetailed" class="echarts"></lineAreas>
        </div>
        <div class="contentEcahrtInfo">
          <div>
            <span class="echartTitle">TOP10 报告</span>
            <button class="detailedBtn" @click="showReportDetailed">详细</button>
            <top5Chart :dataList="reportTop10" :title="reportTitle" class="echarts"></top5Chart>
          </div>
          <div>
            <span class="echartTitle">TOP10 用户</span>
            <button class="detailedBtn" @click="showUserDetailed">详细</button>
            <top5Chart :dataList="userTop10" :title="userTitle" :type="userType" class="echarts"></top5Chart>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>

import lineAreas from "@/components/echarts/lineAreas";
import top5Chart from "@/components/echarts/top5Chart";
import detaile from "./detaile";
import {getBuriedPointJournal} from '@/api/api'
import emitter from '@/utils/emitter';

export default {
  name: "accessStatistics",
  components: {
    lineAreas,
    top5Chart,
    detaile
  },
  props: {
    apendId: {
      required: true
    },
    time: {
      required: true
    }
  },
  mixins: [emitter],
  computed: {
    selectTime() {
      if (this.time) {
        const s = this.time[0].split(" ")[0]
        const e = this.time[1].split(" ")[0]
        if (s != e) {
          return s + " - " + e
        } else {
          return s
        }
      } else {
        return ""
      }
    }
  },
  data() {
    return {
      key:0,
      val: {
        val: 1
      },
      userType: "user_name",
      activeName: 'second',
      lineData: [],
      accessTrendsName: '',
      newAccessTrendsList: [],
      reportTop10: [],
      reportTitle: [],
      userTop10: [],
      userTitle: [],
      showDetailed: false,
      type: '',
      header: [],
      detaileArr: [],
      userId: '',
      mobile: '',
      name: '',
      departmentName: '',
    };
  },
  methods: {
    showDetailedFun() {
      this.showDetailed = false
      this.$emit('changeShowBack', false)

    },
    queryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    getQueryVariable(variable) {
      //alert(window.location.href)
      var query = window.location.href.split('?')
      if (query.length == 1) {
        return (false)
      }
      query = window.location.href.split('?')[1]
      if (window.location.href.split('?').length > 2) {
        query = query + '&' + window.location.href.split('?')[2];
      }
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return (false);
    },

    showReportDetailed() {
      this.detaileArr = []
      this.type = "report_name"
      this.header = [
        '报告名称',
        '模块名称',
        '发布时间',
        '访问次数'
      ]
      this.reportTop10.map(m => {
        this.detaileArr.push({
          first: m.name,
          second: m.date,
          third: m.mediaType,
          forth: m.count
        })
      })
      this.dispatch('changeSelectShow', [false], 'index')
      this.showDetailed = true
      this.$emit('changeTitle', '报告访问详情')
      this.$emit('changeShowBack', true)
    },
    showUserDetailed() {
      this.detaileArr = []
      this.type = "user_name"
      this.header = [
        '姓名',
        '部门',
        '报告访问次数',
      ]
      this.userTop10.map(m => {
        this.detaileArr.push({
          first: m.name,
          second: m.title,
          third: m.count
        })
      })
      this.dispatch('changeSelectShow', [false], 'index')
      this.showDetailed = true
      this.$emit('changeTitle', '用户访问详情')
      this.$emit('changeShowBack', true)
    },

    async getAccessTrends() {
      this.newAccessTrendsList = []
      try {
        if (!this.apendId) {
          return
        }
        let data = {
          agentId: String(this.apendId),
          groupColunm: "visit_time",
          startTime: this.time[0],
          endTime: this.time[1],
          orderByClause: "desc",
        }
        let result = await getBuriedPointJournal(data)
        let x = []
        let y = []
        this.accessTrendsName = result[0].name

        JSON.parse(result).data.map(m => {
          x.push(m.date)
          y.push(m.count)
        })
        this.newAccessTrendsList.push({
          name: this.accessTrendsName,
          x: x,
          y: y
        })
        this.key++

      } catch (e) {
        console.log(e)
      }
    },
    async getReportTop10List() {
      this.reportTop10 = []
      if (!this.apendId) {
        return
      }
      let data = {
        agentId: String(this.apendId),
        groupColunm: "report_name",
        startTime: this.time[0],
        endTime: this.time[1],
        limitTop: '10',
        orderByClause: "desc"
      }
      let result = await getBuriedPointJournal(data)
      this.reportTop10 = JSON.parse(result).data
      this.reportTitle = [{
        firstTitle: "报告名称",
        secondTitle: '发布时间',
        times: '访问次数'
      }]
    },
    async getUser10List() {
      this.userTop10 = []
      if (!this.apendId) {
        return
      }
      let data = {
        agentId: String(this.apendId),
        groupColunm: "user_name",
        limitTop: '10',
        orderByClause: "desc",
        startTime: this.time[0],
        endTime: this.time[1],
      }
      let result = await getBuriedPointJournal(data)
      this.userTop10 = JSON.parse(result).data
      this.userTop10.map(m => {
        m.name = m.mediaName
        m.date = m.title
      })
      this.userTitle = [{
        firstTitle: "姓名",
        secondTitle: '部门',
        times: '访问次数'
      }]
    },
  },
  watch: {
    time: {
      handler() {
        this.getAccessTrends()
        this.getReportTop10List()
        this.getUser10List()
      },
      deep: true
    },
    apendId() {
      this.getAccessTrends()
      this.getReportTop10List()
      this.getUser10List()
    }
  },
  async created() {
    await this.getAccessTrends()
    await this.getReportTop10List()
    await this.getUser10List()
  },
}
</script>

<style scoped lang="less">
section {
  width: 100%;
  height: 100%;
}

.accessStatistics {
  position: relative;
  background-color: white;
  height: 100%;
  width: 100%;

  .detailedBtn {

    /*font-weight: 700;*/
    font-size: 14px;
    background-color: #0086F9;
    color: white;
    float: right;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    box-shadow: 1px 1px 1px #0086F9;
  }

  .access {
    width: 100%;
    padding: 0 20px;
    height: 100%;
    box-sizing: border-box;
  }

  .echartTitle {
    font-weight: 700;
    font-size: 18px;
    color: #43546C;

  }

  .echarts {
    //height:290px;
    margin-top: 10px;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 2px 2px #EBEBEB;
  }

  .contentEcahrtInfo {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    > div {
      width: 49%;
      height: 200px;
    }
  }

  .showTime {
    color: #747A98;
    text-align: center;
  }
}


.datePicker {
  display: inline-block;
}
</style>
<style lang="less">
.el-picker-panel__body-wrapper {
  width: 100%;
  overflow: hidden;
}

.el-picker-panel.el-date-range-picker.el-popper {
  /*top: 96px!important;*/
}

.el-picker-panel__body-wrapper > .el-picker-panel__body {
  position: relative;
  top: 10px;
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 0 !important;

  .el-date-range-picker__content.is-left {
    /*padding: 0;*/
    width: 50%;
  }

  .el-date-range-picker__content.is-right {
    /*padding: 0;*/
    width: 50%;
  }
}

@media only screen and (max-width: 762px) {
  .el-picker-panel.el-date-range-picker.el-popper {
    width: 100%;
    left: 0 !important;
    top: 95px !important;
    display: flex;
    justify-content: center;

    > .el-picker-panel__body-wrapper > .el-picker-panel__body {
      display: flex;
      flex-wrap: wrap;

      .el-date-range-picker__content.is-left {
        /*max-width: 400px;*/
        width: 100%;
        border-right: 0;
      }

      .el-date-range-picker__content.is-right {
        /*max-width: 400px;*/
        width: 100%;
      }
    }
  }

}
</style>
