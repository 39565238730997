import { render, staticRenderFns } from "./accessStatistics.vue?vue&type=template&id=3d452e80&scoped=true&"
import script from "./accessStatistics.vue?vue&type=script&lang=js&"
export * from "./accessStatistics.vue?vue&type=script&lang=js&"
import style0 from "./accessStatistics.vue?vue&type=style&index=0&id=3d452e80&scoped=true&lang=less&"
import style1 from "./accessStatistics.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d452e80",
  null
  
)

export default component.exports